import { IoCheckmarkCircle } from 'react-icons/io5'
import './ConfirmBookingPage.css'
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { cartAtom, showBookingDrawer, showBookingSuccessMessageAtom, showSelectServices, storeDetailAtom } from '../App/App_state';
import { selectedDateAtom, selectedTimeAtom } from '../Booking/Booking_state';
import { isDayOff } from '../Booking/Booking';
import { add, addHours } from 'date-fns';
import { useEffect } from 'react';

export default function ConfirmBookingPage(props: {
    isRetailStore: boolean,
}) {
    const setShowBookingSuccessMessage = useSetAtom(showBookingSuccessMessageAtom)
    const setShowSelectServices = useSetAtom(showSelectServices)
    const toggleShowBookingDrawer = useSetAtom(showBookingDrawer)


    const setCart = useSetAtom(cartAtom)
    const setSelectedTime = useSetAtom(selectedTimeAtom)
    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom)

    const storeDetails = useAtomValue(storeDetailAtom)

    const storeHolidays = (storeDetails?.holidays ?? []).map(e => e.toDateString())

    useEffect(() => {
        const now = addHours(new Date(), 24)
        let dateToMove = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        while (isDayOff(dateToMove, storeHolidays)) {
            const nextDate = add(dateToMove, { days: 1 })
            dateToMove = new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
        }

        if (dateToMove.toDateString() !== selectedDate.toDateString()) {
            setSelectedDate(dateToMove);
        }

        setCart([])
        toggleShowBookingDrawer(false)
        setShowSelectServices(true)
        setSelectedTime(null)
    }, [])

    return (
        <div id='ConfirmBookingPage' className='canvasDark'>
            <IoCheckmarkCircle size={278} className='CanvasDark' />
            <h2>{`Your ${props.isRetailStore ? 'Order placed' : 'Appointment Booked'} Successfully!`} </h2>
            <p> {`We will update you soon.`}  </p>

            <button
                style={{ width: '172px', height: '42px', color: 'black' }}
                className='canvas'
                aria-label='Go Home'
                onClick={() => {
                    setShowBookingSuccessMessage(false)
                }}
            >Go Home</button>
        </div>
    )
}

import { StoreDetail } from '../App/App_Store_interface';
import { IconWrapper, formatPhoneNumber } from '../GetInTouch/GetInTouch';

import { IoLocationOutline, IoCallOutline, IoMailOutline } from 'react-icons/io5';

export default function SalonCard(props: {
  storeDetail: StoreDetail,
  isRetailStore: boolean,
}): JSX.Element {

  return (
    <div
      id='salonCard_container'
      className='canvas paddingMin'
      style={{
        display: 'grid',
        gap: '7px',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <h2 style={{
          paddingLeft: '14px',
          fontSize: '32px',
        }}>
          {props.storeDetail.name} <br />
          {props.storeDetail.chainName} <br />
        </h2>

        <div style={{ height: '124px' }}>
          <img
            src={props.storeDetail.logo}
            className='radiusMax'
            alt='logo'
            width={'100%'}
            height={'100%'}
            style={{
              objectFit: 'contain'
            }}
          />
        </div>

        {props.storeDetail.totalStore > 1 &&
          <a
            href={`https://booking.invoay.com/${window.location.pathname.split('/').at(1)}`}
            style={{
              padding: '4px',
              color: 'blue',
              height: '42px'
            }}
          >
            change location
          </a>
        }


        <div style={{
          display: 'grid',
          gap: '1px',
          gridAutoRows: '42px 42px 42px 42px'
        }}>
          {[{
            icon: <IoLocationOutline size={24} />,
            value: `${props.storeDetail.address.line1},  ${props.storeDetail.address.line2}`,
            href: `https://maps.google.com/?q=${(props.storeDetail.address.placeId !== undefined && props.storeDetail.address.placeId !== null) ? props.storeDetail.address.placeId : `${props.storeDetail.address.lat},${props.storeDetail.address.long}`}`,
            isInActive: props.storeDetail.address.lat === undefined || props.storeDetail.address.lat === null,
          }, {
            icon: <IoCallOutline size={24} />,
            value: formatPhoneNumber(props.storeDetail.contactInfo.phoneNumber),
            href: `tel://${props.storeDetail.contactInfo.phoneNumber}`,
            isInActive: props.storeDetail.contactInfo.phoneNumber === undefined || props.storeDetail.contactInfo.phoneNumber === null,
          }, {
            icon: <IoMailOutline size={24} />,
            value: props.storeDetail.contactInfo.email,
            href: `mailto:${props.storeDetail.contactInfo.email}`,
            isInActive: props.storeDetail.contactInfo.email === undefined || props.storeDetail.contactInfo.email === null,
          }
          ]
            .filter(e => e.isInActive === false)
            .map((product, i) =>
              <a
                key={i}
                href={product.href}
                target="_blank"
                rel="noreferrer"
                aria-label={product.value}
              >
                <IconWrapper child={product.icon} />
              </a>
            )}

        </div>
      </div>

      <div style={{
        borderBottom: '0.2px solid lightgray',
      }}></div>

      <div style={{
        alignContent: 'center',
        textAlign: 'center',
      }}>
        Online Booking Platform
      </div>

    </div>
  )
}

import "./SearchBar.css"

import { Item } from "../App/App_Store_interface";
import { IoSearchSharp } from "react-icons/io5";
import { collectionGroup, getDocs, getFirestore, limit, orderBy, query, where, QuerySnapshot, DocumentData } from "firebase/firestore";
import { useSetAtom } from "jotai";
import { searchQueryAtom, searchResultAtom } from "./SearchBarState";


async function performSearch(searchValue: string, storeId: string, storeType: string): Promise<QuerySnapshot<DocumentData>> {
    console.log(searchValue);
    return getDocs(
        query(
            collectionGroup(getFirestore(), 'items'),
            orderBy("name"),
            where('isVisibleInFirestore', '==', true),
            where('name', '>=', searchValue),
            where('name', '<=', searchValue + '~'),
            limit(24)
        )
    );
}

function formatDocs(docs: QuerySnapshot<DocumentData>, storeId: string, storeType: string): Item[] {
    return docs.docs
        .filter(d => d.ref.path.includes(storeId))
        .filter(d => d.ref.path.includes(storeType === 'retail-booking' ? 'productCategories' : 'serviceCategories'))
        .map(d => ({
            ...d.data(),
            id: d.id,
        } as Item));
}

export default function SearchBar() {

    const setSearchQuery = useSetAtom(searchQueryAtom)
    const setSearchResult = useSetAtom(searchResultAtom)

    return (
        <div id='searchBar' className='canvas radiusMax' >
            <IoSearchSharp size={'22'} />
            <input
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    const searchValue = e.target.value;
                    setSearchQuery(searchValue);

                    if (searchValue.length > 2) {
                        try {
                            setSearchResult([]);
                            const [, , storeId, storeType] = window.location.pathname.split('/');
                            const docs = await performSearch(`${searchValue[0].toUpperCase()}${searchValue.split('').splice(1, searchValue.length).join('')}`, storeId, storeType);
                            const formattedDocs = formatDocs(docs, storeId, storeType);
                            setSearchResult(formattedDocs);
                        } catch (error) {
                            console.error("Failed to perform search:", error);
                        }
                    } else {
                        setSearchResult([]);
                    }
                }}
                type='text'
                placeholder='search items' />
        </div>);
}

import { IoCheckmarkSharp } from 'react-icons/io5'
import './ServiceTile.css'
import { useAtom, useAtomValue } from 'jotai';
import { cartAtom, storeDetailAtom } from '../App/App_state';
import { Item } from '../App/App_Store_interface';

// store item detail ListTile
export default function ServiceTile(props: {
  service: Item,
}) {
  // cart getter and setter  
  const [cart, addToCart] = useAtom(cartAtom)
  const storeDetail = useAtomValue(storeDetailAtom)!

  // is item already in cart
  let isInsideCart = cart.map(e => e.id).includes(props.service.id)



  return (
    <button aria-label='Add To Cart' onClick={() => {
      const customerSpendDurationInMinutes = cart.length === 0 ? 0 : cart.map((item) => item.durationInMinutes * item.cartQty).reduce((a, b) => a + b)

      if (
        storeDetail.maxBookingTimeInMinutes !== undefined
        && storeDetail.maxBookingTimeInMinutes !== null
        && (customerSpendDurationInMinutes + props.service.durationInMinutes) > storeDetail.maxBookingTimeInMinutes!
      ) {
        alert('Max 120 Mins Allowed')
        return;
      }

      props.service.cartQty = 1

      if (isInsideCart) {
        addToCart(cart.filter((service) => service.id !== props.service.id))
      } else {
        addToCart([...cart, props.service]);
      }
    }}>
      <div className='ServiceTile'>

        {isInsideCart
          ? <div className='checkBox checkBox_check canvasDark'>
            <IoCheckmarkSharp color='white' size={22} />
          </div>
          : <div className='checkBox'></div>}

        <div>
          <h3>{props.service.name}</h3>
          <small>{props.service.durationInMinutes === undefined ? props.service.uom : formatDuration(props.service.durationInMinutes)}</small>
        </div>

        <h3>{`₹ ${formatCost(props.service.cost.toString())}`}</h3>


      </div>
    </button>
  )
}

// format number eg, 1,22,333
export function formatCost(cost: string) {
  return cost.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// format minutes to string eg, 15 mins, 2 hrs etc
export function formatDuration(durationInMinutes: number): string {
  let res = '';
  const date = new Date()
  date.setHours(0, durationInMinutes, 0, 0)

  const [hours, minutes] = date.toTimeString().split(' ')[0].split(':')

  if (hours !== '00') {
    res = `${parseInt(hours)} hrs`
  }
  if (minutes !== '00') {
    res = `${res} ${minutes} mins`
  }
  return res
}
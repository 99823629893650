import { atom } from "jotai"
import { Bookings } from "../App/App_Store_interface"
import { addHours } from "date-fns"

// state atom holds selected employee id,
const selectedEmployeeIdAtom = atom<string | undefined>(undefined)

// state atom store selected date
const selectedDateAtom = atom<Date>(addHours(new Date(), 24))

// state atom store selected dateTime
const selectedTimeAtom = atom<Date | null>(null)

// state atom hold list of booking objects for selected date
const bookingsTimeAtom = atom<Array<Bookings>>([])

export { selectedDateAtom, selectedTimeAtom, bookingsTimeAtom, selectedEmployeeIdAtom }
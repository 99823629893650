import { ChangeEventHandler } from 'react'
import './TextField.css'

export default function TextField(props: {
  label: string
  value: string
  onChange: ChangeEventHandler<any>
}) {
  return (
    <div className='input-group'>
      {props.label === 'Address' || props.label === 'Remark'
        ? <textarea aria-label={props.label} value={props.value} onChange={props.onChange} rows={4} maxLength={512} required ></textarea>
        : <input type='text' aria-label={props.label} value={props.value} onChange={props.onChange} required />
      }
      <span className='highlight'></span>
      <span className='bar'></span>
      <label> {props.label} </label>
    </div>
  )
}

function DropDownField(props: {
  label: string
  values: Array<string>
  onChange: ChangeEventHandler<any>
}) {
  return (<div>
    <p style={{ color: 'purple', paddingBottom: '4px' }}> {props.label}</p>
    <select
      onChange={props.onChange}
      style={{
        borderRadius: '14px',
        border: 'solid grey 0px',
        width: '100%',
        padding: '12px',
        fontSize: ' 17px',
      }}>
      {
        props.values.map(v =>
          <option key={v} value={v}>{v}</option>
        )
      }</select>
  </div>)
}

export { DropDownField }
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { isAfter, add, addDays, addHours, addMinutes, format, subDays, subMinutes, endOfWeek, startOfWeek, addWeeks, subWeeks } from 'date-fns'
import './Booking.css'
import { useEffect, useState } from 'react'

import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { cartAtom, storeDetailAtom } from '../App/App_state'
import { bookingsTimeAtom, selectedDateAtom, selectedEmployeeIdAtom, selectedTimeAtom } from './Booking_state'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'


// app booking screen layout
export default function Booking(props: {
    isRetailStore: boolean
}) {
    const storeDetail = useAtomValue(storeDetailAtom)!

    return (
        <div id='Booking'>
            {(storeDetail.defaultEmployeeId !== undefined && storeDetail.defaultEmployeeId !== null)
                ? <></>
                : <div>
                    <h3 className='cardLabel canvas'>Select Staff</h3>
                    <EmployeeSelector />
                </div>
            }
            <div>
                <h3 className='cardLabel canvas'>Select Date</h3>
                <DateSelector />
            </div>
            {
                storeDetail.isSetLastAppointmentTimeEnable === true
                && <LastAppointmentTypePicker />
            }

            {props.isRetailStore === false && (storeDetail.isSetLastAppointmentTimeEnable ?? false) === false && <div>
                <h3 className='cardLabel canvas' style={{
                    borderRadius: '14px',
                    borderBottomLeftRadius: '0',
                    borderBottomRightRadius: '0',
                }}>Select Time</h3>
                <TimeSelector />
            </div>}
        </div>
    )
}


// employees selector component
function EmployeeSelector() {

    // selected employee id state
    const [selectedEmployeeId, setSelectedStaff] = useAtom(selectedEmployeeIdAtom)

    // all store employees list
    const employees = useAtomValue(storeDetailAtom)!.employees

    // time selector set to null when employee is change
    const setSelectedTime = useSetAtom(selectedTimeAtom)

    if ((employees?.length ?? 0) <= 1) return <></>

    return (
        <div id='EmployeeSelector' className='canvas'>
            {employees!.map((employee, i) =>
                <button key={i} aria-label='Staff' onClick={() => {
                    setSelectedTime(null)
                    setSelectedStaff(employee.id)
                }} className='StaffSelectorItem'>
                    <div className={employee.id === selectedEmployeeId ? 'StaffSelectorCheckBox canvasDark' : 'StaffSelectorCheckBox'} style={{ backgroundColor: employee.id === selectedEmployeeId ? 'skyblue' : '' }}></div>
                    {`${employee.firstName} ${employee?.lastName ?? ''}`}
                </button>
            )}
        </div>
    )
}

// date selector component
function DateSelector() {

    // state of selected date
    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom)

    // state of selected employee id
    const selectedEmployeeId = useAtomValue(selectedEmployeeIdAtom)

    // when date changes set selected time to null
    const setSelectedTime = useSetAtom(selectedTimeAtom)

    // when date changes fetch and set booking for that date
    const setBookingsTime = useSetAtom(bookingsTimeAtom)

    const storeDetails = useAtomValue(storeDetailAtom)

    const storeHolidays = (storeDetails?.holidays ?? []).map(e => e.toDateString())

    const storeDetail = useAtomValue(storeDetailAtom)!


    // if selected date in holiday move date to next date and try again
    useEffect(() => {
        let dateToMove = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())

        while (isDayOff(dateToMove, storeHolidays)) {
            const nextDate = add(dateToMove, { days: 1 })
            dateToMove = new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
        }

        if (dateToMove.toDateString() !== selectedDate.toDateString()) {
            setSelectedDate(dateToMove);
        }
    }, [])


    useEffect(() => {
        if ((storeDetails?.defaultEmployeeId !== null || storeDetails.defaultEmployeeId !== undefined) && (storeDetail.isSetLastAppointmentTimeEnable === undefined || storeDetail.isSetLastAppointmentTimeEnable === null)) {
            return;
        }

        // after change set booking to empty array for every date change
        setBookingsTime([])

        // get store id from url path
        const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)

        setSelectedTime(null)

        if ((storeType ?? 'booking') === 'booking') {
            // get bookings doc for selected date
            getDocs(
                query(
                    collection(
                        getFirestore(),
                        `chains/${chainId}/stores/${storeId}/employees/${selectedEmployeeId}/bookings`
                    ),
                    where("datetimeFrom", ">", new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())),
                    where("datetimeFrom", "<", new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1))
                )
            ).then((d) => {
                // after successfully fetch booking set booked times
                setBookingsTime(
                    d.docs.map((e) => {
                        return {
                            datetimeFrom: new Date(e.data().datetimeFrom.seconds * 1000),
                            datetimeTo: new Date(e.data().datetimeTo.seconds * 1000)
                        }
                    })
                )
                if (storeDetail.isSetLastAppointmentTimeEnable === true) {
                    setSelectedTime(null)
                }
            })
        }
        // fetch booking and set only for changes in selected date and selected employees id
    }, [selectedEmployeeId, selectedDate])

    return (
        <div
            className='canvas'
            style={{
                padding: '7px',
                borderTopLeftRadius: '0',
            }}
        >
            <Calender
                selectedMonth={selectedDate}
                onDateChange={(d: Date) => setSelectedDate(d)}
            />
        </div>
    )
}


function LastAppointmentTypePicker() {

    // store detail state
    const storeDetail = useAtomValue(storeDetailAtom)!

    // selected time getter and setter 
    const [selectedTime, setSelectedTime] = useAtom(selectedTimeAtom)

    // selected get getter
    const selectedDate = useAtomValue(selectedDateAtom)

    // how much time customer services require get it from cart items duration sum
    const customerSpendDurationInMinutes = useAtomValue(cartAtom).map((item) => item.durationInMinutes * item.cartQty).reduce((a, b) => a + b)

    // get from booking docs and map it to booked datetime
    const bookedTimes = useAtomValue(bookingsTimeAtom).map((e) => genTimeString(e.datetimeFrom, e.datetimeTo)).flat().map((d) => d.toTimeString())

    // copy of selected date state
    const date = new Date(selectedDate.getTime())

    const now = new Date()

    const storeTimeFrom = new Date((storeDetail.storeTimes as any)[date.getDay()]?.timeFrom ?? now);
    const storeTimeTo = new Date((storeDetail.storeTimes as any)[date.getDay()]?.timeTo ?? now);

    // if selected date is current date add 1 hour and set to min 0
    // for other date set time to 09:00
    date.setHours(
        selectedDate.toDateString() === new Date().toDateString()
            ? selectedDate.getHours() + 1 : storeTimeFrom.getHours(),
        0, 0, 0,
    )


    // generate times array of interval 15 mins
    // and generated times should be in range of store time
    let times = Array(72)
        .fill(7)
        .map((_, i) => add(date, { minutes: 15 * i }))
        .filter((d) => {
            return d.toDateString() === date.toDateString()
                && d.getHours() >= storeTimeFrom.getHours()
                && d.getHours() < storeTimeTo.getHours()
        })


    // from customer spend time in minutes generate overlap times
    const overLapTimes = times
        .filter((t) => !bookedTimes.includes(t.toTimeString()))
        .map((t) => {
            return genTimeString(t, add(t, { minutes: customerSpendDurationInMinutes }))
                .map((d) => bookedTimes.includes(d.toTimeString())).includes(true) ? t : false
        }).filter((e) => e !== false).map((t) => (t as Date).toTimeString())

    const lastSessionOverlapTime: Date | null = times.length === 0 ? null : subMinutes(addMinutes(times[times.length - 1], 15), customerSpendDurationInMinutes)

    const lastAvailableTime = times.filter(time => {
        const isOverlapTime = bookedTimes.includes(time.toTimeString()) || overLapTimes.includes(time.toTimeString()) || (lastSessionOverlapTime != null && isAfter(time, lastSessionOverlapTime))
        return !isOverlapTime;
    })[0]

    if (selectedTime === null) {
        if (lastAvailableTime !== undefined) {
            setSelectedTime(lastAvailableTime);
        }
    }



    return <h3 className='canvas' style={{
        borderRadius: '14px',
        padding: '14px',
    }}>{`Appointment Time: ${selectedTime === null ? 'Not Available for selected Date' : format(selectedTime!, 'p').padStart(8, '0')}`}</h3>
}

function TimeSelector() {
    // store detail state
    const storeDetail = useAtomValue(storeDetailAtom)!

    // selected time getter and setter 
    const [selectedTime, setSelectedTime] = useAtom(selectedTimeAtom)

    // selected get getter
    const selectedDate = useAtomValue(selectedDateAtom)

    // how much time customer services require get it from cart items duration sum
    const customerSpendDurationInMinutes = useAtomValue(cartAtom).map((item) => item.durationInMinutes * item.cartQty).reduce((a, b) => a + b)

    // get from booking docs and map it to booked datetime
    const bookedTimes = useAtomValue(bookingsTimeAtom).map((e) => genTimeString(e.datetimeFrom, e.datetimeTo)).flat().map((d) => d.toTimeString())

    const now = new Date()

    // copy of selected date state
    const date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())

    const storeTimeFrom = new Date((storeDetail.storeTimes as any)[date.getDay()]?.timeFrom ?? now);
    const storeTimeTo = new Date((storeDetail.storeTimes as any)[date.getDay()]?.timeTo ?? now);


    // generate times array of interval 15 mins
    // and generated times should be in range of store time
    let times = Array(72)
        .fill(7)
        .map((_, i) => add(date, { minutes: 15 * i }))
        .filter((d) => {
            return d.toDateString() === date.toDateString()
                && d.getHours() >= storeTimeFrom.getHours()
                && d.getHours() < storeTimeTo.getHours()
        })


    // from customer spend time in minutes generate overlap times
    const overLapTimes = times
        .filter((t) => !bookedTimes.includes(t.toTimeString()))
        .map((t) => genTimeString(t, add(t, { minutes: customerSpendDurationInMinutes }))
            .map((d) => bookedTimes.includes(d.toTimeString())).includes(true) ? t : false)
        .filter((e) => e !== false)
        .map((t) => (t as Date).toTimeString())

    const lastSessionOverlapTime: Date | null = times.length === 0 ? null : subMinutes(addMinutes(times[times.length - 1], 15), customerSpendDurationInMinutes)


    return (
        <div>
            <div className='TimeSelector canvas'>
                {times.map((time, e) => {
                    if (bookedTimes.includes(time.toTimeString()) || overLapTimes.includes(time.toTimeString()) || (lastSessionOverlapTime != null && isAfter(time, lastSessionOverlapTime))) {
                        return (
                            <div key={e} className='SelectorItem unavailableDate'>
                                {format(time, 'p').padStart(8, '0')}
                            </div>
                        )
                    }

                    return (
                        <a
                            key={e}
                            href='#Cart'
                            aria-label='Time'
                            onClick={() => setSelectedTime(time)} >
                            <div
                                className={
                                    time.toTimeString() === selectedTime?.toTimeString()
                                        ? 'SelectorItemCheck SelectorItem canvasDark'
                                        : 'SelectorItem'
                                }>
                                {format(time, 'p').padStart(8, '0')}
                            </div>
                        </a>
                    )
                }
                )}
            </div>
        </div>
    )


}

// generate times array of interval 15 mins for given interval of date
function genTimeString(dateTimeFrom: Date, dateTimeTo: Date) {
    return Array(72)
        .fill(7)
        .map((_, i) => add(dateTimeFrom, { minutes: 15 * i }))
        .filter((d) => d < dateTimeTo)
}


function Calender(props: {
    selectedMonth: Date,
    onDateChange: Function,
}) {
    const isLargeScreen = window.matchMedia("(min-width: 972px)").matches;

    const weeksDaysName = Array(7).fill(true).map((v, i) => new Date(2023, 8, 10 + i).toLocaleDateString('en-us', { weekday: 'short' }))

    let [selectedWeek, setSelectedWeek] = useState(startOfWeek(props.selectedMonth))

    const daysInMonth = getDatesInWeek(selectedWeek)

    const storeHolidays = (useAtomValue(storeDetailAtom)?.holidays ?? []).map(e => e.toDateString())


    const today = new Date()

    return <div>
        {/* month name */}
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <p>
                {`${selectedWeek.toLocaleDateString('en-us', { month: 'long' })} ${selectedWeek.getFullYear()}`}
            </p>

            <div style={{ width: '14px' }}></div>

            <div style={{
                display: 'flex',
            }}>
                {today.getTime() < selectedWeek.getTime()
                    ? <button onClick={() => setSelectedWeek(subWeeks(selectedWeek, 2))}>
                        <IoChevronBack size={20} />
                    </button>
                    : <IoChevronBack size={20} color='grey' style={{ cursor: 'not-allowed' }} />
                }
                {selectedWeek.getTime() < addDays(today, 30).getTime()
                    ? <button onClick={() => setSelectedWeek(addWeeks(selectedWeek, 2))} >
                        <IoChevronForward size={20} />
                    </button>
                    : <IoChevronForward size={20} color='grey' style={{ cursor: 'not-allowed' }} />
                }
            </div>

        </div>

        <div style={{
            display: 'grid',
            gap: '2px',
            padding: '7px 0',
            gridTemplateColumns: 'auto auto auto auto auto auto auto',
            textAlign: 'center',
        }}>
            {weeksDaysName.map(e => {
                return <p key={e}>{e}</p>
            })}
        </div>

        <div style={{
            display: 'grid',
            gap: '4px',
            gridTemplateColumns: 'auto auto auto auto auto auto auto',
            textAlign: 'center',
        }}>
            {
                daysInMonth.map((d, i) => {
                    if (
                        new Date(d.setHours(0, 0, 0, 0)) < addHours(new Date(today.setHours(0, 0, 0, 0)), 24) || // hide 24 hour from now
                        isDayOff(d, [])
                    ) {
                        return <div
                            key={i}
                            style={{
                                borderRadius: '14px',
                                border: '0.1px solid grey',
                                backgroundColor: 'LightGray',
                                aspectRatio: isLargeScreen ? '2' : '1.1',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'grey',
                                cursor: 'not-allowed',
                            }}
                        >
                            {d.getDate()}
                        </div>
                    }

                    if (
                        storeHolidays.includes(d.toDateString())
                    ) {
                        return <div
                            key={i}
                            style={{
                                borderRadius: '14px',
                                border: '0.1px solid grey',
                                aspectRatio: isLargeScreen ? '2' : '1.1',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'red',
                                backgroundColor: '#FFCCCB',
                                cursor: 'not-allowed',
                            }}
                        >
                            {d.getDate()}
                        </div>
                    }

                    if (
                        new Date(d.setHours(0, 0, 0, 0)) > addDays(new Date(today.setHours(0, 0, 0, 0)), 31)
                    ) {
                        return <div
                            key={i}
                            style={{
                                borderRadius: '14px',
                                border: '0.1px solid grey',
                                aspectRatio: isLargeScreen ? '2' : '1.1',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'bisque',
                                cursor: 'not-allowed',
                            }}
                        >
                            {d.getDate()}
                        </div>
                    }


                    return <button
                        key={i}
                        className={d.toDateString() === props.selectedMonth.toDateString() ? 'canvasDark' : ''}
                        style={{
                            borderRadius: '14px',
                            border: '0.1px solid grey',
                            aspectRatio: isLargeScreen ? '2' : '1.1',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: d.toDateString() === props.selectedMonth.toDateString() ? 'white' : 'black',
                            fontWeight: d.toDateString() === props.selectedMonth.toDateString() ? 'bold' : 'normal',
                        }}
                        onClick={() => {
                            if (props.selectedMonth.toDateString() !== d.toDateString()) {
                                props.onDateChange(d)
                            }
                        }}
                    >
                        {d.getDate()}
                    </button>
                })
            }
        </div>
    </div >;
}


function getDatesInWeek(date: Date): Array<Date> {
    const dates: Array<Date> = [];

    const currentWeekStart = startOfWeek(date);
    const currentWeekEnd = endOfWeek(date);

    // Add previous week's days
    for (let weekDay = currentWeekStart.getDay() - 1; weekDay >= 0; weekDay--) {
        dates.unshift(subDays(currentWeekStart, weekDay));
    }

    // Add current week's days
    for (let day = 1; day <= 7; day++) {
        const date = new Date(currentWeekStart);
        date.setDate(currentWeekStart.getDate() + day - 1);
        dates.push(date);
    }

    // Add next week's days
    for (let weekDay = 1; weekDay <= 7; weekDay++) {
        dates.push(addDays(currentWeekEnd, weekDay));
    }
    return dates;
}

/**
 * Checks if a given date is the first or third Saturday of its month.
 * @param date The date to check.
 * @returns True if the date is the first or third Saturday, false otherwise.
 */
function isDayOff(date: Date, holidays: Array<String>): boolean {
    // Get the date's day of the week (0 = Sunday, 6 = Saturday)
    const dayOfWeek = date.getDay();

    if (dayOfWeek === 0) {
        return true;
    }

    if (dayOfWeek === 6 && ((Math.floor(date.getDate() / 7) === 0 || Math.floor(date.getDate() / 7) === 2) === false)) {
        return true;
    }

    if (holidays.includes(date.toDateString())) {
        return true;
    }

    return false;
}


export { isDayOff }
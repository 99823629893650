import { useEffect, useState } from "react"

import './CarouselSlider.css'

// component show slider of images with pre and next button,
export default function CarouselSlider(props: {
  images: Array<string>
}) {

  // index to which current image is shown
  let [showImageIndex, setShowImageIndex] = useState(0)

  useEffect(() => {
    // at interval of 2000 milliseconds change index of image to show
    let setIntervalVariable = setInterval(() => {
      setShowImageIndex(getIndex())

    }, 2000);
    // cancel the timer
    return () => clearTimeout(setIntervalVariable)
  }, [])

  function getIndex(prev?: boolean) {
    // return next and pre index 
    // of current index is at edge return first or last index
    if (prev) {
      return showImageIndex === 0 ? props.images.length - 1 : --showImageIndex
    }
    return showImageIndex === props.images.length - 1 ? 0 : ++showImageIndex
  }

  return (
    <div id='slider-container'>
      {props.images.map((image, i) => (
        <img key={i} src={image} style={{ display: i === showImageIndex ? 'block' : 'none' }} alt='salonImages' />
      ))}
      <div id='sliderButton'>
        <a href='#slider-container' aria-label='Slider' className="prev" onClick={() => setShowImageIndex(getIndex(true))} >&#10094;</a>
        <a href='#slider-container' aria-label='Slider' className="next" onClick={() => setShowImageIndex(getIndex())} >&#10095;</a>
      </div>
    </div>
  )
}